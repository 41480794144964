<template>
  <div class="topic" id="pageTop">
    <div class="content">
      <a-spin :spinning="loading">
        <div class="cards">
          <div class="head">
            <h5>{{ $t('Lab_Group_TopicList') }}</h5>
            <!-- 话题列表 -->
            <a-dropdown
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              placement="bottomRight"
            >
              <span class="sort">
                {{ sortKey === "hot" ? $t('bbs.hot_content') : $t('bbs.recommended_content') }}
                <!-- "按热度" : "按推荐" -->
                <DownOutlined />
              </span>
              <template #overlay>
                <a-menu>
                  <a-menu-item @click="sortChange('recommend')"
                    >{{ $t('bbs.recommended_content') }}</a-menu-item
                  >
                  <!-- 按推荐 -->
                  <a-menu-item @click="sortChange('hot')">{{ $t('bbs.hot_content') }}</a-menu-item>
                  <!-- 按热度 -->
                </a-menu>
              </template>
            </a-dropdown>
          </div>
          <div
            class="item"
            v-for="item in topicData"
            :key="item.topicId"
            @click="topicJump(item)"
          >
            <p class="name">#{{ item.topicName }}#</p>
            <div class="nums">
              <span>{{ $t('bbs.post_count') }}：{{ item.posts }}</span>
              <!-- 帖子数： -->
              <span>{{ $t('bbs.quesition_count') }}：{{ item.questions }}</span>
              <!-- 问题数： -->
              <span>{{ $t('bbs.article_count') }}：{{ item.articles }}</span>
              <!-- 文章数： -->
            </div>
          </div>
          <div class="page-Wrap" v-if="topicData.length">
            <a-pagination
              hideOnSinglePage
              show-quick-jumper
              :defaultPageSize="10"
              v-model:current="currentPage"
              :show-total="(total) => $t('XB_InTotal', [pageTotal])"
              :total="pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a-button v-if="type === 'prev'">{{ $t('pre') }}</a-button>
                <a-button v-else-if="type === 'next'">{{ $t('next') }}</a-button>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
          <a-empty v-if="!topicData.length" style="padding: 50px 0" />
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { getTopicList } from "@/api/bbs";
const renderVNode = (_, { attrs: { vnode } }) => vnode;

export default {
  components: {
    renderVNode,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      newWindow: computed(() => store.getters.companyInfo.useNewWindow == 1),
      topicData: [],
      loading: true,
      currentPage: 1,
      pageTotal: 1,
      sortKey: "recommend",
    });

    const getList = (scroll = false) => {
      state.loading = true;
      getTopicList({
        status: 1,
        page: state.currentPage,
        pageSize: 10,
        sortKey: state.sortKey,
        sort: 1,
      }).then((res) => {
        state.loading = false;
        if (res.ret === 0) {
          state.pageTotal = res.data.totals;
          state.topicData = res.data.list || [];
          if (scroll) {
            setTimeout(() => {
              document.getElementById("pageTop").scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 0);
          }
        }
      });
    };

    const sortChange = (type) => {
      if (state.sortKey === type) return false;
      state.sortKey = type;
      state.currentPage = 1;
      getList();
    };

    const pageChange = (n) => {
      state.currentPage = n;
      getList(true);
    };

    getList();

    const topicJump = (item) => {
      if (state.newWindow) {
        window.open(`/bbs/topic?ddtab=true&topicId=${item.topicId}`);
      } else {
        router.push({
          path: "/bbs/topic",
          query: {
            topicId: item.topicId,
          },
        });
      }
    };

    return {
      ...toRefs(state),
      sortChange,
      pageChange,
      topicJump,
    };
  },
};
</script>

<style lang="less" scoped>
.topic {
  padding: 22px 0 100px;
  .content {
    background-color: #fff;
    .mixinWrap();
    .cards {
      margin-bottom: 10px;
      min-height: 500px;
      .head {
        padding: 0 26px;
        height: 66px;
        .mixinFlex(space-between; center);
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        h5 {
          font-size: 20px;
          font-weight: normal;
          color: #202020;
          margin: 0;
        }
        .sort {
          color: #666666;
          cursor: default;
        }
      }
      .item {
        margin: 0 26px;
        height: 72px;
        .mixinFlex(space-between; center);
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        font-size: 16px;
        line-height: 22px;
        cursor: pointer;
        .name {
          margin: 0;
          color: #333;
          max-width: calc(100% - 390px);
        }
        .nums {
          width: 390px;
          .mixinFlex(space-between; center);
          span {
            width: 130px;
            text-align: right;
            color: #999;
          }
        }
        &:hover {
          .name {
            color: @color-theme;
          }
        }
      }
    }
  }
}
</style>
